import { $fetch } from 'ofetch'
import { onServerPrefetch } from 'vue'
import { toast } from 'vue-sonner'

import { useGlobalStore } from '@/stores/global.js'

import isBrowser from '@/utils/is-browser.js'

const http = $fetch.create({
  baseURL: getBaseUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-LOCALE': 'ru', // TODO: Change
    'X-Requested-With': 'XMLHttpRequest',
    'X-TIMEZONE': Intl?.DateTimeFormat().resolvedOptions().timeZone,
  },
  onResponseError({ response }) {
    if (response.status === 419) {
      toast.error('Ой!', {
        description: 'Вы делаете слишком много запросов.',
      })
    }
  },
})

export function getBaseUrl(suffix = '/api') {
  return isBrowser
    ? import.meta.env.VITE_API_ENDPOINT ?? suffix
    : 'http://127.0.0.1' + suffix
}

export default async function useHttp(url, options = {}) {
  const fetchRequest = async () => {
    try {
      const global = useGlobalStore()

      // If request queried from browser and exists in global
      // state, then extract data from global state.
      if (isBrowser && options.cacheKey && global.cache[options.cacheKey]) {
        return global.cache[options.cacheKey]
      }

      const response = await http(url, options)

      // Otherwise - set data inside cache store.
      if (options.cacheKey) {
        global.cache[options.cacheKey] = response
      }

      return response
    } catch (e) {
      console.error(e.stack)
      throw e
    }
  }

  if (import.meta.env.SSR) {
    return new Promise((resolve, reject) => {
      onServerPrefetch(async () => {
        try {
          const result = await fetchRequest()
          resolve(result)
        } catch (e) {
          console.error(e.stack)
        }
      })
    })
  } else {
    try {
      return await fetchRequest()
    } catch (e) {
      throw e
    }
  }
}
