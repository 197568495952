import { useDark } from '@vueuse/core'
import { onBeforeMount, onServerPrefetch } from 'vue'

import useSSRCookies from '@/utils/get-cookies.js'
import isBrowser from '@/utils/is-browser.js'

export default function () {
  const darkComputedRef = isBrowser
    ? useDark({
        selector: 'body',
        onChanged(isDark, defaultHandler, mode) {
          const date = new Date()
          date.setFullYear(date.getFullYear() + 1)

          useSSRCookies().set('_thm', isDark ? 'dark' : 'light', {
            expires: date,
            path: '/',
          })

          defaultHandler(mode)
        },
      })
    : useDark({
        selector: 'body',
      })

  onServerPrefetch(() => {
    const cookies = useSSRCookies()

    const theme = cookies.get('_thm')

    darkComputedRef.value = theme === 'dark'
  })

  return darkComputedRef
}
