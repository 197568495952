import { createAutoHeightTextareaPlugin } from '@formkit/addons'
import { ru } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'

import { asteriskPlugin, loadingPlugin } from '@/plugins/formkit-plugins.js'

import tailwindTheme from './formkit.theme.js'

export default {
  locales: { ru },
  locale: 'ru',
  icons: {
    ...genesisIcons,
    checkboxDecorator: `<svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="3"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 12l5 5l10 -10" /></svg>`,
  },
  plugins: [asteriskPlugin, loadingPlugin(), createAutoHeightTextareaPlugin()],
  config: {
    classes: generateClasses(tailwindTheme),
  },
  inputs: {},
}
