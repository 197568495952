import { defineStore } from 'pinia'
import { getCurrentInstance, ref } from 'vue'

import isBrowser from '@/utils/is-browser.js'

export const useGlobalStore = defineStore('global', () => {
  const req = ref(null)
  const cache = ref(isBrowser ? {} : {})

  return {
    req,
    cache,
  }
})
