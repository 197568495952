import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import Default from '@/layouts/default.vue'
import isBrowser from '@/utils/is-browser.js'

let routes = [
  {
    path: '/',
    component: () => import('@/pages/index.vue'),
    name: 'index',
    meta: {
      layout: Default,
    },
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (from.name && (from.name.startsWith(to.name) || from.name === to.name)) {
    return savedPosition || to.meta?.scrollPos
  }
  return savedPosition || { left: 0, top: 0 }
}

export default function () {
  const router = createRouter({
    history: isBrowser ? createWebHistory('/') : createMemoryHistory('/'),
    routes: [
      ...routes,
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        name: '404',
        meta: {
          layout: Default,
        },
      },
    ],
    scrollBehavior,
  })

  router.beforeEach((to, from, next) => {
    if (from.meta?.scrollPos && to.name && to.name.startsWith(from.name)) {
      from.meta.scrollPos.top = window.scrollY
      from.meta.scrollPos.left = 0
    }
    next()
  })

  return router
}
